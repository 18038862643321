<template>
  <div class="wrapper">
    <div class="profile">
      <div class="header">@{{ profile["username"] }}</div>
      <div class="divider"></div>
      <div class="profile-body">
        <div class="info">
          <div class="photo">
            <div class="img-cropper">
              <img :src="profile['photoURL']" alt="" />
            </div>
          </div>
          <div class="name">
            {{ profile["name"] }}
          </div>
          <div class="bio">
            {{ profile["bio"] }}
          </div>
        </div>
        <div class="links">
          <div class="pink" v-for="link in links" :key="link.key">
            <a class="link-name link" v-bind:href="link.url">
              <img
                class="link-icon"
                :src="getImgUrl(link.id)"
                v-bind:alt="link.id"
              />
              {{ link.name }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="logo">
      <a href="https://www.macoapp.cz">
        <img src="../assets/logo.svg" />
      </a>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import firebase from "firebase";
import "../firebaseConfig";

import "firebase/firestore";

const db = firebase.firestore();

export default {
  data() {
    return {
      profile: [],
      links: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    getImgUrl(link) {
      var images = require.context("../assets/social_icons", false, /\.png$/);
      return images("./" + link + ".png");
    },
    fetchData() {
      db.collection("users")
        .doc(this.$route.params.id)
        .get()
        .then((snapshot) => {
          this.profile = snapshot.data();
          // console.log(this.profile);
          console.log(this.profile);
        });
      db.collection("users")
        .doc(this.$route.params.id)
        .collection("links")
        .get()
        .then((querySnapshot) => {
          const documents = querySnapshot.docs.map((doc) => doc.data());
          this.links = documents;
          console.log(documents);
          querySnapshot.forEach((doc) => {
            if (doc.id == this.profile["directLink"]) {
              // console.log("DIRECT:" + doc.id);
              // console.log(doc.data()["url"]);
              // doc.data()["imglink"] = "google.cz";
              window.location.href = doc.data()["url"];
            }
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;700&display=swap");
body {
  font-family: Raleway;
  background-color: #fcfafa;

  body * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
}
.profile {
  background-color: #fcfcfc;
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: border-box;
  border-radius: 25px;
  box-shadow: #4307141c 0px 0px 20px;
  min-height: 95vh;
  // width: 100%;
  max-width: 100%;
  width: 400px;
  margin: 0px auto !important;
  margin-top: 40px;
  .header {
    border-top-left-radius: 23px;
    border-top-right-radius: 23px;
    text-align: center;
    background-color: #db143d;
    // border-bottom-left-radius: ;
    height: 50px;
    padding-top: 18px;
    font-size: 18px;
    font-weight: lighter;
    color: #fcfafa;
  }
  // .divider {
  //   width: 100%;
  //   height: 15px;
  //   margin-top: -23px;
  //   border-top-left-radius: 22px;
  //   border-top-right-radius: 22px;
  //   background-color: #FCFAFA;
  // }
  .photo {
    background-image: url("~@/assets/image-deco.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    // padding: 0 10%;
    padding: 7%;
    // width: 50%;
    margin: 5px auto;
  }
  .photo img {
    padding: 0%;
    height: 150px;
    position: relative;
    // left: -50%;
  }
  .img-cropper {
    // max-width: 100%;
    border-radius: 100%;
    overflow: hidden;
    height: 150px;
    width: 150px;
    margin: 0 auto;
  }
  .profile-body {
    padding-top: 5%;
    padding-bottom: 5%;
    padding-left: 15%;
    padding-right: 15%;
    border-radius: 22px;
    // border-bottom-left-radius: 22px;
    // border-bottom-right-radius: 22px;
    margin-top: -22px;
    background-color: #fcfafa;
    .info {
      text-align: center;
      // margin-top: -150px;

      .name {
        font-size: 23px;
        letter-spacing: 100%;
        margin-top: 20px;
        margin-bottom: 15px;
      }
    }
    .links {
      padding-top: 15px;
      a {
        text-decoration: none;
        font-size: 18px;
        color: #111;
      }
      .link {
        padding-top: 13px;
        padding-bottom: 13px;
        display: flex;
        align-items: center;
        .link-icon {
          width: 35px;
          margin-right: 20px;
        }
      }
    }
  }
}
.logo {
  margin-top: 25px;
  text-align: center;
  img {
    width: 230px;
  }
}
</style>
// src/firebaseConfig.js
import firebase from "firebase";

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyA67utwink0MwE3t-Lnc7hw4zgAmNGbyk0",
    authDomain: "macoapp-724bc.firebaseapp.com",
    databaseURL: "https://macoapp-724bc.firebaseio.com",
    projectId: "macoapp-724bc",
    storageBucket: "macoapp-724bc.appspot.com",
    messagingSenderId: "772372251435",
    appId: "1:772372251435:web:77711843b019beecb27b6e",
    measurementId: "G-Z5YTQ1HBK3"
  };


// Initialize Firebase
export default firebase.initializeApp(firebaseConfig);